import '../css/cards_container.css'
import playBettingButton from '../img/play_sport_button.png'

import playCasinoButton from '../img/play_casino_button.png'
import { useState, useEffect } from 'react'


function CasinoCardsContainer ({games, activeCategory, setGames}) {
    const borderColor = activeCategory === 'Казино' ? '#15FF00' : '#FFB700'
    const [updatedData, setUpdatedData] = useState(games);
    const [isAnimationDelaySetted, setIsAnimationDelaySetted] = useState(false);

    useEffect(() => {

      const setGamesDelay = () => {
        const data = updatedData.map((item, idx) =>
          idx === 0 ? { ...item, animationDelay: 0 } :
          idx === 1 || idx === 3 ? { ...item, animationDelay: 0.7 } :
          idx === 2 || idx === 4 || idx === 6 ? { ...item, animationDelay: 0.7 * 2 } :
          idx === 5 || idx === 7 || idx === 9 ? { ...item, animationDelay: 0.7 * 3 } :
          idx === 8 || idx === 10 || idx === 12 ? { ...item, animationDelay: 0.7 * 4 } :
          idx === 11 || idx === 13 || idx === 15 ? { ...item, animationDelay: 0.7 * 5 } :
          idx === 14 || idx === 16 || idx === 18 ? { ...item, animationDelay: 0.7 * 6 } :
          idx === 17 || idx === 19 || idx === 21 ? { ...item, animationDelay: 0.7 * 7 } :
          idx === 20 || idx === 22 || idx === 24 ? { ...item, animationDelay: 0.7 * 8 } :
          idx === 23 || idx === 25 || idx === 27 ? { ...item, animationDelay: 0.7 * 9 } :
          idx === 26 || idx === 28 || idx === 30 ? { ...item, animationDelay: 0.7 * 10 } :
          idx === 29 || idx === 31 || idx === 33 ? { ...item, animationDelay: 0.7 * 11 } :
          idx === 32 || idx === 34 || idx === 36 ? { ...item, animationDelay: 0.7 * 12 } :
          idx === 35 || idx === 37 || idx === 39 ? { ...item, animationDelay: 0.7 * 13 } :
          idx === 38 || idx === 40 || idx === 42 ? { ...item, animationDelay: 0.7 * 14 } :
          idx === 41 || idx === 43 || idx === 45 ? { ...item, animationDelay: 0.7 * 15 } :
          idx === 44 || idx === 46 || idx === 48 ? { ...item, animationDelay: 0.7 * 16 } :
          { ...item, animationDelay: 0.7 * 17 }

        );
        
        setUpdatedData(data)
      };
      
      if (!isAnimationDelaySetted) {
        setGamesDelay();
        setIsAnimationDelaySetted(true);
      }
      
    }, [activeCategory, games, setGames, updatedData, isAnimationDelaySetted])
      

    const setItemClick = (id) => {
      // Обновляем массив, изменяя только isClicked у нажатого элемента
      const data = updatedData.map(item =>
        item.id === id ? { ...item, isClicked: !item.isClicked } : { ...item, isClicked: false }
      );
      setUpdatedData(data); // Обновляем состояние с новым массивом
      
    };

    const chunkArray = (array, chunkSize) => {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          result.push(array.slice(i, i + chunkSize));
        }
        return result;
      };
    
      // Разбиваем массив игр на группы по 3 элемента
      const gameChunks = chunkArray(updatedData, 3);

      const navigateToPage = (link) => {
        
        window.open(link, '_self');
      }

    return(
        <div className="cards-container">
            {gameChunks.map((chunk, index) => (
          <div className="cards-container-row" key={index}>
            {chunk.map((game, idx) => (
              <div
                className="container-card"
                style = {{
                  border: '2px solid #304553'
                }}
                key={idx}>
                <div className={`card-image ${game.isClicked ? 'expanded' : ''}`}
                 style={{
                  backgroundImage: `url(${game.image})`,
                  }} onClick={!game.isClicked ? (() => setItemClick(game.id)) : (() => navigateToPage(game.link))}>
                {game.isClicked && (
                  <div className='card-filter'>
                    <div className='card-filter-text-area'>
                        <div className='card-filter-text'>
                          {game.title}
                        </div>
                    </div>
                    <div className='card-filter-image-container'>
                      <img className="card-filter-image" src = {activeCategory === 'Казино' ? playCasinoButton : playBettingButton} alt = 'error'/>
                    </div>
                  </div>)}
                  <div className='gradient' style={{visibility: 'hidden'}}></div>
                    </div>
                    <div className="card-fs-section" style={{color: borderColor}}>
                        <div className="card-fs-text">{game.free_spins_and_bonuses}</div>
                    </div>  
                </div>
            ))}
          </div>
        ))}
        <div className='breakable-line'></div>
        </div>

    )
};

export default CasinoCardsContainer;